import React, { useState } from "react";
import "./AddTrader.css";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

import { loginUser } from "../../../actions/authActions";
import classnames from "classnames";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

import FullPageLoader from "../../../FullPageLoader/FullPageLoader";

const AddTrader = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();

  const [traderData, setTraderData] = useState({
    UserID: props.auth.user.id,
    Date: null,
    MT4_Acct_No: null,
    MT4_Password: null,
    BrokerName: null,
    InitialBalance: null,
    BrokerServer: null,
    Status: "pending",
  });
  const [traderDataMsg, setTraderDataMsg] = useState({
    UserID: props.auth.user.id,
    Date: "",
    MT4_Acct_No: "",
    MT4_Password: "",
    BrokerName: "",
    InitialBalance: "",
    BrokerServer: "",
    Status: "Registered",
  });
  const submit = () => {
    if (
      traderData.UserID &&
      traderData.Date &&
      traderData.MT4_Acct_No &&
      traderData.MT4_Password &&
      traderData.BrokerName &&
      traderData.InitialBalance &&
      traderData.BrokerServer &&
      traderData.Status
    ) {
      setShowLoader(true);
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/trader_acct`, traderData)
        .then((res) => {
          navigate("/acounts");
        })
        .catch((err) => {});
    } else {
      setTraderDataMsg({
        Date: !traderData.Date ? "This Field is required" : "",
        MT4_Acct_No: !traderData.MT4_Acct_No ? "This Field is required" : "",
        MT4_Password: !traderData.MT4_Password ? "This Field is required" : "",
        BrokerName: !traderData.BrokerName ? "This Field is required" : "",
        InitialBalance: !traderData.InitialBalance
          ? "This Field is required"
          : "",
          BrokerServer: !traderData.BrokerServer ? "This Field is required" : "",
      });
    }
  };

  if (props.auth.user.Verified) {
    return (
      <>
        {showLoader && <FullPageLoader></FullPageLoader>}
        <div className="form-page">
          <div className="form-center">
            <div className="midas-form">
              <div className="form-mb">
                <h3 className="h3">ADD CLIENT</h3>
                <Row>
                  <Col md={6}>
                    <div className="form-group">
                      <label className="d-block">Start Date</label>
                      <input
                        type="date"
                        value={moment(traderData.Date, "DD-MM-YYYY").format(
                          "YYYY-MM-DD"
                        )}
                        onChange={(e) => {
                          setTraderData({
                            ...traderData,
                            Date: moment(e.target.value)
                              .utc()
                              .format("DD-MM-YYYY"),
                          });
                          if (e.target.value) {
                            setTraderDataMsg({ ...traderDataMsg, Date: "" });
                          }
                        }}
                      />
                      {traderDataMsg.Date ? (
                        <small className="error">{traderDataMsg.Date}</small>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <label className="d-block">MT4 Account No</label>
                      <input
                        type="text"
                        placeholder="Type here"
                        value={traderData.MT4_Acct_No}
                        onChange={(e) => {
                          setTraderData({
                            ...traderData,
                            MT4_Acct_No: e.target.value,
                          });

                          if (e.target.value) {
                            setTraderDataMsg({
                              ...traderDataMsg,
                              MT4_Acct_No: "",
                            });
                          }
                        }}
                      />
                      {traderDataMsg.MT4_Acct_No ? (
                        <small className="error">
                          {traderDataMsg.MT4_Acct_No}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <label className="d-block">MT4 Password</label>
                      <input
                        type="text"
                        placeholder="Assign password"
                        value={traderData.MT4_Password}
                        onChange={(e) => {
                          setTraderData({
                            ...traderData,
                            MT4_Password: e.target.value,
                          });

                          if (e.target.value) {
                            setTraderDataMsg({
                              ...traderDataMsg,
                              MT4_Password: "",
                            });
                          }
                        }}
                      />
                      {traderDataMsg.MT4_Password ? (
                        <small className="error">
                          {traderDataMsg.MT4_Password}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <label className="d-block">Initial Balance</label>
                      <input
                        type="number"
                        placeholder="Type here"
                        value={traderData.InitialBalance}
                        onChange={(e) => {
                          setTraderData({
                            ...traderData,
                            InitialBalance: e.target.value,
                          });
                          if (e.target.value) {
                            setTraderDataMsg({
                              ...traderDataMsg,
                              InitialBalance: "",
                            });
                          }
                        }}
                      />
                      {traderDataMsg.InitialBalance ? (
                        <small className="error">
                          {traderDataMsg.InitialBalance}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <label className="d-block">Broker Name</label>
                      <input
                        type="text"
                        placeholder="Type here "
                        value={traderData.BrokerName}
                        onChange={(e) => {
                          setTraderData({
                            ...traderData,
                            BrokerName: e.target.value,
                          });

                          if (e.target.value) {
                            setTraderDataMsg({
                              ...traderDataMsg,
                              BrokerName: "",
                            });
                          }
                        }}
                      />
                      {traderDataMsg.BrokerName ? (
                        <small className="error">
                          {traderDataMsg.BrokerName}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <label className="d-block">Broker Server</label>
                      <input
                        type="text"
                        placeholder="Type here"
                        value={traderData.BrokerServer}
                        onChange={(e) => {
                          setTraderData({
                            ...traderData,
                            BrokerServer: e.target.value,
                          });

                          if (e.target.value) {
                            setTraderDataMsg({
                              ...traderDataMsg,
                              BrokerServer: "",
                            });
                          }
                        }}
                      />
                      {traderDataMsg.BrokerServer ? (
                        <small className="error">
                          {traderDataMsg.BrokerServer}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <Link to='/acounts'>
                    <button className="theme-link">Cancel</button>
                  </Link>
                </div>
                <button className="gold-btn" onClick={submit}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <Navigate to="/login" />;
  }
};

AddTrader.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});
export default connect(mapStateToProps, { loginUser })(AddTrader);

// export default AddTrader;

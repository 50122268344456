import React from "react";
import { useState, useEffect } from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import "./DashboardHome.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faStore, faSpinner} from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faTelegram, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";

const DashboardHome = (props) => {
    const [userData, setUserData] = useState([]);
    const [traders, setTraders] = useState([]);

    useEffect(() => {
        totalTraders()
        axios
            .get(
                `${process.env.REACT_APP_BASE_URL}/auth_user/getsingleuser/${props.auth.user.id}`
            )
            .then((response) => {
                const data = response.data[0];
                setUserData(data);
            });

        axios
            .get(
              `${process.env.REACT_APP_BASE_URL}/usertraderacct/customertraderacct/${props.auth.user.id}/all`
            )
            .then((res) => {
              // res => {}
              setTraders(res.data);
            })
            .catch((err) => {});
    }, []);

    const [totalTrader, setTotalTrader] = useState(0);

    const totalTraders = async () => {
        try {
            let response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/trader_acct/traders/total/${props.auth.user.id}`
            );
            setTotalTrader(response.data.data);
        } catch (error) {
            toast.error("error in response");
        }
    };

    return (
        <>
            
                <div className="dashboard">
                    <Container fluid>
                        <div className="d-flex justify-content-sm-between align-items-center mb-md-4 mb-2">
                            <h4>
                                Hello!{" "}
                                <span className="theme-color">
                                    {userData.FirstName} {userData.LastName}
                                </span>
                            </h4>
                        </div>
                        {
                            !totalTrader &&
                            <Alert variant={'danger'} className="text-center">
                                There is no trading account. <Link to="/addTrader" style={{'color':'red'}}target="_blank">Kindly add one.</Link> 
                            </Alert>
                        }
                        <div className="salesGraph mb-3">
                            <Row>
                                <Col lg={8} md={12} className="mb-lg-0 mb-3">
                                    <div className="graph">
                                        <div className="row justify-content-md-between align-items-center mb-md-4 mb-2">
                                            <h4>Accounts</h4>
                                            <ul>
                                                {traders.map((trader, index) => {
                                                    return(
                                                        <li key={index}><strong>MT4 Account:</strong>  {trader.MT4_Acct_No} &nbsp; <FontAwesomeIcon icon={trader.Status  == "live" ? faCheck : faSpinner } style={{ color:trader.Status == "live"? 'green': 'red', fontSize: "25px" }} /></li>
                                                    )
                                                })}

                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={4} md={12} className="mb-md-0 mb-3 mx-lg-0 mx-auto">
                                    <p>
                                        <a href="http://instagram.com/goldtradermo" target="_blank">
                                            <FontAwesomeIcon icon={faInstagram} size="lg"/>
                                            &nbsp; @goldtradermo 
                                        </a>
                                    </p>
                                    <p>
                                        <a href="http://t.me/goldtradermo" target="_blank">
                                            <FontAwesomeIcon icon={faTelegram}  size="lg"/>
                                            &nbsp; @goldtradermo 
                                        </a>
                                    </p>
                                    <p>
                                        <a href="https://gtmostore.com/" target="_blank">
                                            <FontAwesomeIcon icon={faStore}  size="lg"/>
                                            &nbsp; gtmostore.com
                                        </a>
                                    </p>
                                    <p>
                                        <a href="http://t.me/goldtraderinfo" target="_blank">
                                            <FontAwesomeIcon icon={faTelegram}  size="lg"/>
                                            &nbsp; @goldtraderinfo  
                                        </a>
                                    </p>
                                    <p>
                                        <a href=" https://wa.me/message/FM6JX5SA7JY7G1" target="_blank">
                                            <FontAwesomeIcon icon={faWhatsapp}  size="lg"/>
                                            &nbsp; Contact us on Whatsapp  
                                        </a>
                                    </p>
                                    {/* <div className="searchBy">
                                        <div className="tomorrow"
                                            data-location-id=""
                                            data-language="EN"
                                            data-unit-system="METRIC"
                                            data-skin="dark"
                                            data-widget-type="upcoming"
                                            style={{position:'relative'}}
                                        > {" "}
                                        </div>
                                    </div> */}
                                </Col>
                                <Col md={12} className="mt-4">
                                    <p><strong>Risk Warning:</strong> Trading derivatives carries significant risks. It is not suitable for all investors, Past performance is no indication of future performance and we do not provide you with 100% guarantees</p>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
        </>
    );
};

DashboardHome.propTypes = {
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});
export default connect(mapStateToProps, { loginUser })(DashboardHome);

// export default DashboardHome;

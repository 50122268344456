import React from 'react'
import './SuccessfulTrader.css'
import {Container, Row, Col} from 'react-bootstrap'
import tradeGraph from '../../../assets/images/tradeGraph.png'



const SuccessfulTrader = () => {
    return(
        <>
            <div className='section'>
                <div className='successfulTrader'>
                    <Container fluid className='wideBox'>
                        <Row className='align-items-center'>
                            <Col md={6} className='mb-md-0 mb-3'>
                                <div className='traderWidth'>
                                    <div className='tickBall'></div>
                                    <h2 className='theme-color'>Succesful traders choose gold signals!</h2>
                                    <p><span className='fw-bold'>MIDAS GOLDEN ARMY -</span> unlike other trading signal providers gains fromthe positive  investments of our members. That is, we go out of our way to assist you in making the best decisions.</p>
                                    <button className='gold-Homebtn mt-lg-4 mt-3'>Join Now</button>
                                </div>
                            </Col>
                            <Col md={6} className='mb-md-0 mb-3'>
                                <div className='float-md-end tradeImg'>
                                    <img className='img-fluid' src={tradeGraph} alt='Successful Traders'></img>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}
export default SuccessfulTrader;
import React from 'react'
import Header from './DashboardHeader/Header';
// import Sidebar from './Sidebar/Sidebar';
// import Traders from './Traders/Traders'; 
import DashboardHome from './DashboardHome/DashboardHome';

///////////////////////////
import { loginUser } from '../actions/authActions';
import classnames from "classnames";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Navigate } from 'react-router-dom';
import { logoutUser } from '../actions/authActions';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../Dashboard/Sidebar/Sidebar'

const Dasboard = (props) => {

    const navigate=useNavigate();
    const onLogout = () => {
        props.logoutUser();
        navigate("/login");
      };
      
    // if (props.auth.isAuthenticated ){
        if (props.auth.user.Verified && props.auth.isAuthenticated ){
        /////////// for email verification
            return(
                <>
                    <Header/>
                    <DashboardHome {...props}/>   
                </>
            )
    }
    else{
        return(
        
            <Navigate to="/login"/>
        );
    }
}

Dasboard.propTypes = {
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
  };
  const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
  });
  export default connect(mapStateToProps, { loginUser })(Dasboard);
  
// export default Dasboard;
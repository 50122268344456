import React from 'react'
import './Banner.css'
import {Container} from 'react-bootstrap'
import midas from '../../../assets/images/midas.svg'
import { Link } from 'react-router-dom'

const Banner = () => {
    return(
        <>
            <div className='banner'>
                <Container fluid className='boxContainer pe-0'>
                    <div className='bannerTop'>
                        <img className='img-fluid midasImg' src={midas} alt='MidasGold'></img>
                        <div className='bannerBtm'>
                            <div className='bannerText'>
                                <h1>Trusted source of <span className='theme-color'>Signals</span><br></br>
                                    & the ability to provide account <br></br>
                                    <span className='theme-color'>management</span> for our clients.
                                </h1>
                                <Link to='/login'>
                                <button className='gold-Homebtn'>Join Now</button>
                                </Link>
                                
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    )
}
export default Banner;
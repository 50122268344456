import React, { useEffect } from "react";
import "./EditProfile.css";
import { Row, Col, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
// import Sidebar from '../Sidebar/Sidebar'

///////////////////////////////

import { loginUser } from "../../actions/authActions";
import { updateUser, ImageUpdated } from "../../actions/authActions";
import classnames from "classnames";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import { propTypes } from "react-bootstrap/esm/Image";

////////////////////////////////////
const EditProfile = (props) => {
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    // this.props.verifyEmail(id);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/auth_user/getsingleuser/${props.auth.user.id}`
      )
      .then((response) => {
        const Data = response.data[0];
        setData(Data);
      });
  }, []);

  const navigate = useNavigate();
  const navigateToDashboard = () => {
    // 👇️ navigate to /dasboard
    navigate("/dashboard");
  };

  const [data, setData] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
    AddressLine1: "",
    AddressLine2: "",
    AddressLine3: "",
    Photo: "",
  });

  const callApi = (e) => {
    const formdata=new FormData()
    formdata.append("FirstName",data.FirstName);
    formdata.append("LastName",data.LastName);
    formdata.append("Email",data.Email);
    formdata.append("AddressLine1",data.AddressLine1);
    formdata.append("AddressLine2",data.AddressLine2);
    formdata.append("AddressLine3",data.AddressLine3);
    formdata.append("Photo",data.Photo);

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/auth_user/putupdateuser/${props.auth.user.id}`,
        formdata
      )
      .then((response) => {
        const data = response.data;
        // updtuser();
        // props.updateUser(data.data);
        props.ImageUpdated()
        navigate("/dashboard");
      });
  };


  const onchange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handlePhoto = (e) => [setData({ ...data, Photo: e.target.files[0] })];

  //   if(props.auth.isAuthenticated){
  if (props.auth.user.Verified) {
    return (
      <>
        {/* <Sidebar/> */}
        <div className="MaineditProfile">
          <Container fluid>
            <div className="editProfile wrapBox">
              <div className="d-flex justify-content-sm-between align-items-center mb-md-4 mb-2">
                <h4>Edit Profile</h4>
                {/* <div><button className='gold-outline-btn' >
                                <span className='me-2 fw-bold svgFont'></span>
                                Edit Profile</button></div> */}
              </div>
              <Row>
                <Col sm={6}>
                  <div className="form-group">
                    <label className="d-block">First Name</label>
                    <input
                      type="text"
                      name="FirstName"
                      onChange={onchange}
                      defaultValue={data.FirstName}
                      // value={userData.FirstName}
                      placeholder="Enter your first name"
                    />
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="form-group">
                    <label className="d-block">Last Name</label>
                    <input
                      type="text"
                      name="LastName"
                      onChange={onchange}
                      defaultValue={data.LastName}
                      // value={userData.LastName}
                      placeholder="Enter your last name"
                    />
                  </div>
                </Col>
                <Col sm={12}>
                  <div className="form-group">
                    <label className="d-block">Email</label>
                    <input
                      type="text"
                      name="Email"
                      onChange={onchange}
                      defaultValue={data.Email}
                      // value={userData.Email}
                      placeholder="Enter your email id"
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="form-group">
                    <label className="d-block">Address Line .1</label>
                    <input
                      type="text"
                      name="AddressLine1"
                      onChange={onchange}
                      defaultValue={data.AddressLine1}
                      // value={userData.AddressLine1}
                      placeholder="Type here"
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="form-group">
                    <label className="d-block">Address Line .2</label>
                    <input
                      type="text"
                      name="AddressLine2"
                      onChange={onchange}
                      defaultValue={data.AddressLine2}
                      // value={userData.AddressLine2}
                      placeholder="Type here"
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="form-group">
                    <label className="d-block">Address Line .3</label>
                    <input
                      type="text"
                      name="AddressLine3"
                      onChange={onchange}
                      defaultValue={data.AddressLine3}
                      // value={userData.AddressLine3}
                      placeholder="Type here"
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <label>Add Images</label>
                  <div className="custom custom-file">
                    <input
                      type="file"
                      name="Photo"
                      id="custom-file"
                      className="custom-file-input"
                      onChange={handlePhoto}
                    />
                  </div>
                </Col>
              </Row>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <a href="/dashboard" className="theme-link">
                    Cancel
                  </a>
                </div>
                <button className="gold-btn" onClick={callApi}>
                  Save
                </button>
              </div>
            </div>
          </Container>
        </div>
      </>
    );
  } else {
    return <Navigate to="/login" />;
  }
};

EditProfile.propTypes = {
  updateUser: PropTypes.func.isRequired,
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});
export default connect(mapStateToProps, { loginUser, updateUser,ImageUpdated  })(EditProfile);
// export default EditProfile;

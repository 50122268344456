import React, { Component } from "react";
// import { useState } from "react";
import "../Form.css";
import { Container } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
import logo from "../../../assets/images/formLogo.png";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import FullPageLoader from "../../FullPageLoader/FullPageLoader";
/////////////////////////////

import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import classnames from "classnames";
import { Navigate } from "react-router-dom";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      Email: "",
      Password: "",
      errors: {},
      showLoader: false
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.errors.isError) {
      this.setState({
        errors: nextProps.errors.payload,
      });
    }
    if (nextProps.errors.isError) {
      this.setState({
        showLoader: false
      });
    }
  }

  componentDidUpdate() {
    if (this.state.Email) {
      localStorage.setItem("email", this.state.Email);
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  onSubmit = (e) => {
    e.preventDefault();
    this.setState({
      showLoader: true
    });
    const userData = {
      Email: this.state.Email,
      Password: this.state.Password,
    };
    this.props.loginUser(userData); // since we handle the redirect within our component, we don't need to pass in this.props.history as a parameter
  };

  render() {
    const { errors } = this.state;

    if (!this.props.auth.user.Verified) {
      return (
        <>
        {this.state.showLoader && <FullPageLoader />}
          <div className="form-page">
            <Container fluid>
              <div className="form-center">
                <div className="form-header">

                  <Link to='/'>
                    <figure className="form-logo">
                      <img
                        className="img-fluid"
                        src={logo}
                        alt="Midas Gold"
                      ></img>
                    </figure>
                  </Link>
                </div>
                <div className="midas-form">
                  <form noValidate onSubmit={this.onSubmit}>
                    <div className="form-mb">
                      <h3 className="h3">LOGIN</h3>
                      <div className="form-group">
                        <label className="d-block">Email</label>
                        <input
                          defaultValue={this.state.Email}
                          onChange={this.onChange}
                          error={errors.Email}
                          id="Email"
                          name="Email"
                          type="text"
                          placeholder="Enter your email"
                          className={classnames("", {
                            invalid: errors.Email,
                          })}
                        />
                        <span className="text-danger">{errors.Email}</span>
                      </div>
                      <div className="form-group passwordInput">
                        <label className="d-block">Password</label>
                        <div className="d-flex align-items-center">
                          <input
                            defaultValue={this.state.Password}
                            onChange={this.onChange}
                            error={errors.Password}
                            id="Password"
                            name="Password"
                            type="password"
                            placeholder="Enter your password"
                            className={classnames("", {
                              invalid: errors.Password,
                            })}
                          />
                          <span className="passwordIcon">

                            <AiFillEye />
                            {/* <AiFillEyeInvisible/> */}
                          </span>

                        </div>
                        <span className="text-danger">{errors.Password}</span>
                      </div>
                      <Link to="/forgot">
                        <a
                          className="d-block text-end mt-3"
                        >
                          Forget your password?
                        </a>
                      </Link>

                    </div>
                    <div className="d-flex flex-sm-row flex-column justify-content-sm-between align-items-center">
                      <div className="mb-sm-0 mb-3">
                        Don't have account yet?
                        <Link to="/signup" className="theme-link">
                          Sign up
                        </Link>
                      </div>

                      <button
                        className="gold-btn" type="submit"
                      >
                        Login
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </Container>
          </div>
        </>
      );
    }

    return <Navigate to="/dashboard" />;
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});
export default connect(mapStateToProps, { loginUser })(Login);

import React from "react";
import "../Form.css";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/images/formLogo.png";
import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";

const Forgot = () => {
  const navigate = useNavigate();
  const navigateToDashboard = () => {
    // 👇️ navigate to /dasboard
    navigate("/dashboard");
  };
  const navigateToSignup = () => {
    // 👇️ navigate to /signup
    navigate("/signup");
  };

    const [data, setdata] = useState({
      Email:"",
    });

//   const [Email, setEmail] = useState();

    const onchange = (e) => {
      setdata({ [e.target.id]: e.target.value });
    };

  const sendApi = () => {

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/auth_user/forgetpassword`, data)
      .then((response) => {

        // navigate("/checkemail");
        const Data = response.data;
      });
  };

  return (
    <>
      <div className="form-page">
        <Container fluid>
          <div className="form-center">
            <div className="form-header">
              <figure className="form-logo">
                <img className="img-fluid" src={logo} alt="Midas Gold"></img>
              </figure>
            </div>
            <div className="midas-form">
              <form>
                <div className="form-mb">
                  <h3 className="h3 mb-md-2 mb-1">FORGOT PASSWORD</h3>
                  <p className="theme-color form-teeny-text">
                    A password reset link will be sent to the e-mail we have on
                    file for you.
                  </p>
                  <div className="form-group">
                    <label className="d-block">Email</label>
                    <input
                      type="text"
                      id="Email"
                    //   onChange={(e)=>setEmail(e.target.value)}
                    onChange={onchange}
                      placeholder="Enter your registered email id"
                    />
                  </div>
                </div>
                <div className="d-flex flex-sm-row flex-column justify-content-sm-between align-items-center">
                  <button className="theme-link" onClick={navigateToSignup}>
                    Cancel
                  </button>
                  <Link to="/checkemail">
                  <button className="gold-btn" onClick={sendApi}>
                    Send
                  </button>
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};
export default Forgot;

import React from "react";
import axios from "axios";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { registerUser } from "./actions/authActions";
import classnames from "classnames";
import { Navigate } from "react-router-dom";

const Validator = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // this.props.verifyEmail(id);
    axios
    .post(`${process.env.REACT_APP_BASE_URL}/auth_user/postValidation/${id}`)
    .then((response)=>{
     
      navigate('/dashboard');
      const Data=response.data;
    })
  }, []);

  const validated = () => {
    // alert("Your account has been verified");
    navigate("/dashboard");
  };

  return validated();
};

Validator.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});
export default connect(mapStateToProps, { registerUser })(Validator);
// export default Validator

import React from "react";
import { useEffect } from "react";
import { Container } from "react-bootstrap";
import logo from "../assets/images/formLogo.png";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { CgOpenCollective } from "react-icons/cg";
import Swal from "sweetalert2";
import Validator from "validator";

const ForgetConfirm = () => {
  const navigate = useNavigate();

  const [strongPwd, setStrongPwd] = useState(false);
  const [emptyPwd, setEmptyPwd] = useState(false);

  const { id, token } = useParams();

  const [data, setdata] = useState({
    Password: "",
    Password2: "",
  });

  // useEffect(() => {}, [data]);
  
  const onchange = (e) => {
    setdata({ ...data, [e.target.id]: e.target.value });
    ////////////////////
    if (Validator.isStrongPassword(data.Password, {
      minLength: 8, minLowercase: 1,
      minUppercase: 1, minNumbers: 1, minSymbols: 1
    })) 
    {
      setStrongPwd(true);
    } 
    else
    {
      setStrongPwd(false);
    }
  };

  const onSubmit = async () => {
    if (data.Password !== data.Password2) {
      Swal.fire({
        title: "Passwords donot match",
        icon: "error",
      });
    } else if (data.Password == "" || data.Password == "") {
      Swal.fire({
        title: "Password fields cannot be left empty",
        icon: "error",
      });
    }
    else if(!strongPwd)
    {
      Swal.fire({
        title: "Your Password must contain 8 digits a lowwer, upper case, digit and special character",
        icon: 'error'
    })
    }
    else {
      try {
        let response1 = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/auth_user/resetpassword/${id}/${token}`
        );
        if (response1) {
          try {
            let response2 = await axios.post(
              `${process.env.REACT_APP_BASE_URL}/auth_user/resetpassword/${id}/${token}`,
              data
            );
            if (response2) {
              navigate("/passwordchanged");
            }
          } catch (error) {}
        }
      } catch (error) {
      }
    }
  };

  return (
    <>
      <div className="form-page">
        <Container fluid>
          <div className="form-center">
            <div className="form-header">
              <Link to="/">
                <figure className="form-logo">
                  <img className="img-fluid" src={logo} alt="Midas Gold"></img>
                </figure>
              </Link>
            </div>
            <div className="midas-form">
              <div className="form-mb">
                <div className="form-group">
                  <label className="d-block">Password</label>
                  <input
                    type="password"
                    id="Password"
                    onChange={onchange}
                    placeholder="Enter new password"
                  />
                </div>
                <div className="form-group">
                  <label className="d-block">Confirm Password</label>
                  <input
                    type="password"
                    id="Password2"
                    onChange={onchange}
                    placeholder="Confirm new password"
                  />
                </div>
              </div>
              <div className="d-flex flex-sm-row flex-column justify-content-sm-between align-items-center">
                <button className="gold-btn" onClick={onSubmit}>
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default ForgetConfirm;

import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { Navigate, useNavigate } from "react-router";
import Swal from 'sweetalert2'
import {
    GET_ERRORS,
    SET_CURRENT_USER,
    USER_LOADING,
    SET_VERIFY_EMAIL,
    IMAGE_UPDATED,
    BEOFREIMAGE,
} from "./types";

// Register User
export const beforeAuth = (dispatch) => {
    dispatch({
        type: BEOFREIMAGE
    });
}

export const registerUser = (userData) => (dispatch) => {
    axios
        .post(`${process.env.REACT_APP_BASE_URL}/auth_user/register`, userData)
        .then((res) => {
            // res => {}
            const data = res.data;
            const Email = data.Email;
            localStorage.setItem("email", Email);
            dispatch({
                type: SET_CURRENT_USER,
                payload: res.data,
            });
        })
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
};

export const updateUser = (userData) => (dispatch) => {
    dispatch(setCurrentUser(userData));
};
export const ImageUpdated = (userData) => (dispatch) => {
    dispatch({
        type: IMAGE_UPDATED
    })
};

// verify email

export const verifyEmail = (userData) => (dispatch) => {
    const id = userData;
    const navigate = useNavigate();
    axios
        .post(`${process.env.REACT_APP_BASE_URL}/auth_user/postValidation/${id}`)
        .then((res) => {

            // res => {}
            const data = res.data;
            const Email = data.Email;
            // localStorage.setItem("email", Email);
            dispatch({
                type: SET_VERIFY_EMAIL,
                payload: res.data.Verified,
            });
            // navigate('/login')
        })
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
};

// Login - get user token
export const loginUser = (userData) => (dispatch) => {
    axios
        .post(`${process.env.REACT_APP_BASE_URL}/auth_user/login`, userData)
        .then((res) => {
            const data = res.data;
            const Email = data.Email;
            localStorage.setItem("email", Email);
            // Set token to localStorage
            const { token } = res.data;
            localStorage.setItem("jwtToken", token);
            // Set token to Auth header
            setAuthToken(token);
            // Decode token to get user data
            const decoded = jwt_decode(token);
            // Set current user
            dispatch(setCurrentUser(decoded));
        })
        .catch((err) => {
            let data = err.response.data
            Swal.fire({
                title: data.emailnotfound,
                icon: 'error'
            })
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })

        });
};

// Set logged in user
export const setCurrentUser = (decoded) => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded,
    };
};

// User loading
export const setUserLoading = () => (dispatch) => {
    return {
        type: USER_LOADING,
    };
};

// Log user out
export const logoutUser = () => (dispatch) => {
    // Remove token from local storage
    localStorage.removeItem("jwtToken");
    // Remove auth header for future requests
    setAuthToken(false);
    // Set current user to empty object {} which will set isAuthenticated to false
    dispatch(setCurrentUser({}));
};

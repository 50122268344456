import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate  } from "react-router-dom";
import "./Sidebar.css";
import icon1 from "../../../assets/images/icons/user.svg";
import icon2 from "../../../assets/images/icons/tools.svg";
import icon3 from "../../../assets/images/icons/task.svg";
import icon4 from '../../../assets/images/icons/user.svg'
import icon6 from "../../../assets/images/icons/wallet.svg";
import icon7 from "../../../assets/images/icons/report.svg";
import icon8 from "../../../assets/images/icons/setting.svg";
import icon9 from "../../../assets/images/icons/arrow-right.svg";
import { AiOutlineDoubleRight } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";
///////////////////// for logout
import { logoutUser } from "../../actions/authActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const Sidebar = (props) => {
    // const [sidebar, setSidebar] = useState(true);
    const [show, setShow] = useState(false);
    const [path, setPath] = useState('');
    const navigate = useNavigate();
    const onLogout = () => {

        props.logoutUser();
        navigate("/login");
    };

    const [menu, setMenu] = useState(false);
    useEffect(() => {
        setPath(window.location.pathname.split("/").pop())
    },[])

    return (

        <>
            <div className="sidebar">
                <div
                    className={props.sidebar ? "sidebar-logo" : "logo-change"}
                    onClick={() => props.setSidebar(!props.sidebar)}
                >
                    <AiOutlineDoubleRight />
                </div>
                {props.sidebar ? (
                    <div className="wrapSidebar" onMouseOver={() => setShow(!show)}>
                        <ul className="sidbar-links">
                            <li>
                                <Link to="/dashboard" className={path == 'dashboard' ? 'active':''}>
                                    <span>
                                        <img
                                            className="img-fluid me-2"
                                            alt="Midas Gold"
                                            src={icon1}
                                        ></img>
                                        Dashboard
                                    </span>
                                </Link>
                            </li>
                            <li>
                                <Link onClick={() => setMenu(!menu)}   className={path == 'acounts' ? 'active':''}>
                                    <span className="d-flex">
                                        <div>
                                            <img
                                                className="img-fluid me-2"
                                                alt="Midas Gold"
                                                src={icon2}
                                            ></img>
                                            Accounts
                                        </div>{" "}
                                        <div className="ms-auto">
                                            <IoIosArrowDown></IoIosArrowDown>
                                        </div>
                                    </span>
                                </Link>
                                {menu ? (
                                    <ul className="submenu">
                                        <li>
                                            <Link to="/acounts">
                                                <span>
                                                    <img
                                                        className="img-fluid me-2"
                                                        alt="Midas Gold"
                                                        src={icon3}
                                                    ></img>
                                                    Pending
                                                </span>
                                            </Link>
                                        </li>
                                        <li className="active">
                                            <Link to="/acounts/verified">
                                                <span>
                                                    <img
                                                        className="img-fluid me-2"
                                                        alt="Midas Gold"
                                                        src={icon3}
                                                    ></img>
                                                    Verified
                                                </span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/acounts/live">
                                                <span>
                                                    <img
                                                        className="img-fluid me-2"
                                                        alt="Midas Gold"
                                                        src={icon3}
                                                    ></img>
                                                    Live
                                                </span>
                                            </Link>
                                        </li>
                                        {/* <li>
                                            <Link to="/acounts/paused">
                                                <span>
                                                    <img
                                                        className="img-fluid me-2"
                                                        alt="Midas Gold"
                                                        src={icon3}
                                                    ></img>
                                                    Paused
                                                </span>
                                            </Link>
                                        </li> */}
                                    </ul>
                                ) : null}
                            </li>
                            {/* <li>
                                <Link to="/tasks" className={path == 'tasks' ? 'active':''}>
                                    <span>
                                        <img
                                            className="img-fluid me-2"
                                            alt="Midas Gold"
                                            src={icon3}
                                        ></img>
                                        Tasks
                                    </span>
                                </Link>
                            </li> */}
                            {/* <li>
                                <Link to="/reporting" className={path == 'reporting' ? 'active':''}>
                                    <span>
                                        <img
                                            className="img-fluid me-2"
                                            alt="Midas Gold"
                                            src={icon7}
                                        ></img>
                                        Report
                                    </span>
                                </Link>
                            </li> */}
                            <li>
                                <Link to="/editProfile">
                                    <span>
                                        <img
                                            className="img-fluid me-2"
                                            alt="Midas Gold"
                                            src={icon4}
                                        ></img>
                                        Profile
                                    </span>
                                </Link>
                            </li>
                            <li>
                                <a
                                    className="menu-link"
                                    onClick={onLogout}
                                >
                                    <span>
                                        <i className="fa fa-sign-out" aria-hidden="true"></i> Logout
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                ) : (
                    <div className="hideSidebar">
                        <ul className="sidbar-links">
                        <li>
                                <Link to="/dashboard" className={path == 'dashboard' ? 'active':''}>
                                    <span>
                                        <img
                                            className="img-fluid me-2"
                                            alt="Midas Gold"
                                            src={icon1}
                                        ></img>
                                        
                                    </span>
                                </Link>
                            </li>
                            <li>
                                <Link onClick={() => setMenu(!menu)}   className={path == 'acounts' ? 'active':''}>
                                    <span className="d-flex">
                                        <div>
                                            <img
                                                className="img-fluid me-2"
                                                alt="Midas Gold"
                                                src={icon2}
                                            ></img>
                                            
                                        </div>{" "}
                                        <div className="ms-auto">
                                            <IoIosArrowDown></IoIosArrowDown>
                                        </div>
                                    </span>
                                </Link>
                                {menu ? (
                                    <ul className="submenu">
                                        <li>
                                            <Link to="/acounts">
                                                <span>
                                                    <img
                                                        className="img-fluid me-2"
                                                        alt="Midas Gold"
                                                        src={icon3}
                                                    ></img>
                                                    
                                                </span>
                                            </Link>
                                        </li>
                                        <li className="active">
                                            <Link to="/acounts/verified">
                                                <span>
                                                    <img
                                                        className="img-fluid me-2"
                                                        alt="Midas Gold"
                                                        src={icon3}
                                                    ></img>
                                                    
                                                </span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/acounts/live">
                                                <span>
                                                    <img
                                                        className="img-fluid me-2"
                                                        alt="Midas Gold"
                                                        src={icon3}
                                                    ></img>
                                                    
                                                </span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/acounts/paused">
                                                <span>
                                                    <img
                                                        className="img-fluid me-2"
                                                        alt="Midas Gold"
                                                        src={icon3}
                                                    ></img>
                                                    
                                                </span>
                                            </Link>
                                        </li>
                                    </ul>
                                ) : null}
                            </li>
                            <li>
                                <Link to="/tasks" className={path == 'tasks' ? 'active':''}>
                                    <span>
                                        <img
                                            className="img-fluid me-2"
                                            alt="Midas Gold"
                                            src={icon3}
                                        ></img>
                                        
                                    </span>
                                </Link>
                            </li>
                            {/* <li>
                                <Link to="/reporting" className={path == 'reporting' ? 'active':''}>
                                    <span>
                                        <img
                                            className="img-fluid me-2"
                                            alt="Midas Gold"
                                            src={icon7}
                                        ></img>
                                        
                                    </span>
                                </Link>
                            </li> */}
                            <li>
                                <Link to="/editProfile">
                                    <span>
                                        <img
                                            className="img-fluid me-2"
                                            alt="Midas Gold"
                                            src={icon4}
                                        ></img>
                                        
                                    </span>
                                </Link>
                            </li>
                            <li>
                                <a
                                    className="menu-link"
                                    onClick={onLogout}
                                >
                                    <span>
                                        <i className="fa fa-sign-out" aria-hidden="true"></i> 
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                )}
            </div>
        </>
    );
};
Sidebar.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
    auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(Sidebar);

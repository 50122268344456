import React, { useEffect , useState} from "react";
import { AiOutlineDoubleRight } from "react-icons/ai";
// import FullPageLoader from 'components/FullPageLoader/FullPageLoader'
import Sidebar from "../components/Dashboard/Sidebar/Sidebar";
import Header from '../components/Dashboard/DashboardHeader/Header';

const DashboardLayout = (props)=> {

  const [loader, setLoader] = useState(false)
  let accessToken = localStorage.getItem("accessToken")
  const [sidebar, setSidebar] = useState(true);

  useEffect(() => {
  },[])

    return (
        <>
            <Header/>
            <Sidebar setSidebar={setSidebar} sidebar={sidebar} {...props} />
            {/* <Sidebar {...props} /> */}
            <div
                className={
                    sidebar ? "mainContent wrapPages" : "mainContent wrapPages-change"
                }
            >
            {
                props.children
            }
            </div>
        </>
    );
}

export default DashboardLayout;

import { SET_VERIFY_EMAIL } from "../actions/types";
const isEmpty = require("is-empty");
const initialState = {
  isverified: false,
  image_updated: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_VERIFY_EMAIL:
      return {
        ...state,
        isverified: !isEmpty(action.payload),
        isverified: action.payload,
      };

    default:
      return state;
  }
}

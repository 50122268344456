import React from 'react'
import './SiteCard.css'
import {Container, Row, Col} from 'react-bootstrap'
import analytic1 from '../../../assets/images/analytic1.png'
import analytic2 from '../../../assets/images/analytic2.png'
import analytic3 from '../../../assets/images/analytic3.png'
import analytic4 from '../../../assets/images/analytic4.png'

const SiteCard = () => {
    return(
        <>
            <div className='analytics'>
                <Container fluid className='boxContainer'>
                    <Row>
                        <Col md={4} className='mb-md-0 mb-3'>
                            <div className='analyticCard'>
                                <img className='img-fluid' src={analytic1} alt='MidasGold'></img>
                            </div>
                        </Col>
                        <Col md={2} className='mb-md-0 mb-3'>
                        <div className='analyticCard'>
                                <img className='img-fluid' src={analytic2} alt='MidasGold'></img>
                            </div>
                        </Col>
                        <Col md={2} className='mb-md-0 mb-3'>
                            <div className='analyticCard'>
                                <img className='img-fluid' src={analytic3} alt='MidasGold'></img>
                            </div>
                        </Col>
                        <Col md={4} className='mb-md-0 mb-3'>
                            <div className='analyticCard'>
                                <img className='img-fluid' src={analytic4} alt='MidasGold'></img>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
export default SiteCard;
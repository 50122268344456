import React, { Component } from "react";
import "../Form.css";
import { Container } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import logo from "../../../assets/images/formLogo.png";
import { AiFillEye } from "react-icons/ai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
////////////////////////////////
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { registerUser } from "../../actions/authActions";
import classnames from "classnames";
import FullPageLoader from "../../FullPageLoader/FullPageLoader";
class Signup extends Component {
  constructor() {
    super();
    this.state = {
      FirstName: "",
      LastName: "",
      Email: "",
      Password: "",
      Password2: "",
      AddressLine1: "",
      AddressLine2: "",
      AddressLine3: "",
      errors: {},
      isRegistered: false,
      showLoader: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors.isError) {
      this.setState({
        errors: nextProps.errors.payload,
      });
    }
    if (nextProps.errors.isError) {
      this.setState({
        showLoader: false
      });
    }
    if (nextProps.auth.isAuthenticated) {
      this.setState({
        isRegistered: true
      });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    let valid = true
    let errors = {}
    if(!this.state.FirstName){
      errors.FirstName = "First Name is required"
      valid = false
    }else{
      errors.FirstName = ""
    }
    if(!this.state.LastName){
      errors.LastName= "Last Name is required"
      valid = false
    }else{
      errors.LastName = ""
    }
    if(!this.state.Email){
      errors.Email= "Email is required"
      valid = false
    }else{
      errors.Email = ""
    }
    if(!this.state.AddressLine1){
      errors.AddressLine1= "Address Line is required"
      valid = false
    }else{
      errors.AddressLine1 = ""
    }
    if(!this.state.Password){
      errors.Password= "Password is required"
      valid = false
    }else if(this.state.Password != this.state.Password2){
      errors.Password= "Password does not match"
      valid = false
    }else{
      errors.Password = ""
    }
    if(!valid){
      this.setState({
        errors: errors,
      });
    }else{
      this.setState({
        showLoader: true
      });
      const newUser = {
        FirstName: this.state.FirstName,
        LastName: this.state.LastName,
        Email: this.state.Email,
        AddressLine1: this.state.AddressLine1,
        AddressLine2: this.state.AddressLine2,
        AddressLine3: this.state.AddressLine3,
        Password: this.state.Password,
        Password2: this.state.Password2,
      };
      this.setState({
        showLoader: true
      });
      this.props.registerUser(newUser);
    }
  };

  render() {
    const { errors } = this.state;
    if(this.state.isRegistered){
      return (
        <Navigate to="/thankyou" replace={true} />
      )
    }
    return (
      <>
        {this.state.showLoader && <FullPageLoader />}
        <div className="form-page">
          <Container fluid>
            <div className="form-center">
              <div className="form-header">
                <Link to="/">
                  <figure className="form-logo">
                    <img
                      className="img-fluid"
                      src={logo}
                      alt="Midas Gold"
                      href="/"
                    ></img>
                  </figure>
                </Link>
              </div>
              <div className="midas-form">
                <form noValidate onSubmit={this.onSubmit}>
                  <div className="form-mb">
                    <h3 className="h3">SIGNUP</h3>
                    <Row>
                      <Col md={6}>
                        <div className="form-group">
                          <label className="d-block">First Name</label>
                          <input
                            defaultValue={this.state.FirstName}
                            onChange={this.onChange}
                            error={errors.FirstName}
                            id="FirstName"
                            name="FirstName"
                            type="text"
                            placeholder="Enter your first name"
                            className={classnames("", {
                              invalid: errors.FirstName,
                            })}
                          />
                          <span className="text-danger">
                            {errors.FirstName}
                          </span>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group">
                          <label className="d-block">Last Name</label>
                          <input
                            defaultValue={this.state.LastName}
                            onChange={this.onChange}
                            id="LastName"
                            name="LastName"
                            type="text"
                            placeholder="Enter your last name"
                          />
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="form-group">
                          <label className="d-block">Email</label>
                          <input
                            defaultValue={this.state.Email}
                            onChange={this.onChange}
                            error={errors.Email}
                            id="Email"
                            name="Email"
                            type="text"
                            placeholder="Enter your email id"
                            className={classnames("", {
                              invalid: errors.Email,
                            })}
                          />
                          <span className="text-danger">{errors.Email}</span>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group passwordInput">
                          <label className="d-block abcd1">
                            Password  
                            <abbr title="Your password must contain an uppercase, lowercase, digit and special characters">
                              ⓘ
                            </abbr>
                          </label>
                          <div className="d-flex align-items-center">
                            <input
                              defaultValue={this.state.Password}
                              onChange={this.onChange}
                              error={errors.Password}
                              id="Password"
                              name="Password"
                              type="Password"
                              placeholder="Enter your password"
                              className={classnames("", {
                                invalid: errors.Password,
                              })}
                            />
                            {/* <span className="passwordIcon">
                              <AiFillEye />
                            </span> */}
                          </div>
                          <span className="text-danger">
                            {errors.Password}
                          </span>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group passwordInput">
                          <label className="d-block">Confirm Password</label>
                          <div className="d-flex align-items-center">
                            <input
                              defaultValue={this.state.Password2}
                              onChange={this.onChange}
                              error={errors.Password2}
                              id="Password2"
                              name="Password2"
                              type="Password"
                              placeholder="Enter your password"
                              className={classnames("", {
                                invalid: errors.Password2,
                              })}
                            />
                            {/* <span className="passwordIcon">
                              <AiFillEye />
                            </span> */}
                          </div>
                          <span className="text-danger">
                            {errors.Password2}
                          </span>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="form-group">
                          <label className="d-block">Address Line .1</label>
                          <input
                            defaultValue={this.state.AddressLine1}
                            onChange={this.onChange}
                            error={errors.AddressLine1}
                            id="AddressLine1"
                            name="AddressLine1"
                            type="text"
                            placeholder="Type here"
                            className={classnames("", {
                              invalid: errors.AddressLine1,
                            })}
                          />
                          <span className="text-danger">
                            {errors.AddressLine1}
                          </span>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="form-group">
                          <label className="d-block">Address Line .2</label>
                          <input
                            defaultValue={this.state.AddressLine2}
                            onChange={this.onChange}
                            error={errors.AddressLine2}
                            id="AddressLine2"
                            name="AddressLine2"
                            type="text"
                            placeholder="Type here"
                            className={classnames("", {
                              invalid: errors.AddressLine2,
                            })}
                          />
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="form-group">
                          <label className="d-block">Address Line .3</label>
                          <input
                            defaultValue={this.state.AddressLine3}
                            onChange={this.onChange}
                            error={errors.AddressLine3}
                            id="AddressLine3"
                            name="AddressLine3"
                            type="text"
                            placeholder="Type here"
                            className={classnames("", {
                              invalid: errors.AddressLine3,
                            })}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="d-flex flex-sm-row flex-column justify-content-sm-between align-items-center">
                    <div className="mb-sm-0 mb-3">
                      Already have an account?
                      <Link to="/login">
                        <button className="theme-link">Login</button>
                      </Link>
                    </div>
                    {/* <Link> */}
                    <button className="gold-btn" type="submit">
                      Register
                    </button>
                    {/* </Link> */}
                  </div>
                </form>
              </div>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

Signup.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});
export default connect(mapStateToProps, { registerUser })(Signup);

import React from "react";
import "./Home/Form.css";
import { Container } from "react-bootstrap";
import logo from "../assets/images/formLogo.png"
import { Link } from "react-router-dom";

const PasswordChanged = () => {
  return (
    <div>
      <>
        <div className="form-page">
          <Container fluid>
            <div className="form-center">
              <div className="form-header">
                <Link to="/">
                  <figure className="form-logo">
                    <img
                      className="img-fluid"
                      src={logo}
                      alt="Midas Gold"
                    ></img>
                  </figure>
                </Link>
              </div>
              <div className="midas-form">
                <div>
                  <div className="form-mb">
                    {/* <h3 className='h3 mb-md-2 mb-1'>FORGOT PASSWORD</h3> */}
                    {/* <p className='theme-color form-teeny-text'>A password reset link will be sent to the e-mail we have on file for you.</p> */}
                    <div className="form-group">
                      <label className="d-block">
                        Your password has been updated.
                      </label>
                      <label className="d-block">
                        You can now login with your new password.
                      </label>
                      {/* <input type="text" placeholder='Enter your registered email id' /> */}
                    </div>
                  </div>
                  <div className="d-flex flex-sm-row flex-column justify-content-sm-between align-items-center">
                    <Link to="/login">
                      <button className="gold-btn"> Back to Login</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </>
    </div>
  );
};

export default PasswordChanged;

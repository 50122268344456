import React from "react";
import "./JoinNow.css";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom'

const JoinNow = () => {
  const navigate=useNavigate();
  const navToSignin=()=>{
      navigate('/login');
  }

  return (
    <>
      <div className="joinNow">
        <div className="joinNow-center">
          <p className="fw-bold">
            Join Us And Get The Best Signals In The Market.
          </p>
          <p className="tinyP">Be part of our growing golden army!</p>
          <Link to="/login">
            <button className="gold-Homebtn">Join Now</button>
          </Link>
        </div>
        <div className="joinNowBtm">
          <p className="">
            MIDAS GOLD ARMY{" "}
            <span className="theme-color">ALL RIGHTS RESERVED.</span>
          </p>
        </div>
      </div>
    </>
  );
};
export default JoinNow;

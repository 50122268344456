// import { applyMiddleware, compose } from "redux";

import { configureStore } from '@reduxjs/toolkit'


import thunk from "redux-thunk";
// import rootReducer from "./reducers";
import reducer from "./components/reducers";
import { persistStore } from "redux-persist";
// import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

const preloadedState = {};
// const middleware = [thunk];

// const persistConfig = {
//   key: "root",
//   storage,
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// const store = configureStore(
//   persistedReducer,
//   initialState,
//   compose(
//     applyMiddleware(...middleware),
//     window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
//   )
// );

const store = configureStore({
  reducer,
  middleware: [thunk],
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState,
})



export let persistor = persistStore(store);
export default store;

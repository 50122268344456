import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Table } from "react-bootstrap";
import { CgAdd } from "react-icons/cg";
import "./Traders.css";
import { MdDelete, MdEditOff } from "react-icons/md";
import "../Sidebar/Sidebar.css";
import "reactjs-popup/dist/index.css";
import axios from "axios";
import { loginUser } from "../../actions/authActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Navigate, useParams } from "react-router-dom";

import Swal from "sweetalert2";
const Traders = (props) => {
  let location = useLocation();

  let searchParams = new URLSearchParams(location.search);
  let { status } = useParams();
  const navigate = useNavigate();
  const [traders, setTraders] = useState([]);
  const navigateToAddTrader = () => {
    // 👇️ navigate to /addTrader
    navigate("/addTrader");
  };
  const editTrader = (id) => {
    // 👇️ navigate to /dashboard
    navigate("/editTrader/" + id);
  };
  const settings = {
    centerMode: true,
    centerPadding: "10px",
    slidesToShow: 6,
    speed: 500,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          arrows: true,
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          slidesToShow: 1,
        },
      },
    ],
  };
  const [sidebar, setSidebar] = useState(true);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!status) {
      status = "pending";
    }
    getTraders();
  }, []);

  useEffect(() => {
    getTraders();
  }, [status]);
  const getTraders = () => {
    if (!status) {
      status = "pending";
    }
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/usertraderacct/customertraderacct/` +
          props.auth.user.id +
          "/" +
          status
      )
      .then((res) => {
        // res => {}
        setTraders(res.data);
      })
      .catch((err) => {});
  };
  const deleteTrader = (id) => {
    if(status == "verified" ||
    status == "live" ||
    status == "paused"){
      Swal.fire({
        title: "Please contact us on telegram @goldtraderinfo to disconnect you from the Service.",
        icon: "warning",
      });
    }else{
      axios
        .delete(`${process.env.REACT_APP_BASE_URL}/trader_acct/` + id)
        .then((res) => {
          // res => {}
          getTraders();
        })
        .catch((err) => {});
    }
  };

  if (props.auth.user.Verified) {
    return (
      <>
          <div>
            <div className="traders">
              <Container fluid>
                {/* <div className="wrapAnalytics">
                  <TickerTape colorTheme="dark"></TickerTape>
                </div> */}
                <div className="mainTable">
                  <div className="d-flex justify-content-sm-between align-items-center mb-md-4 mb-2">
                    <h4>Accounts - {status ?? "pending"}</h4>
                    {status == "verified" ||
                    status == "live" ||
                    status == "paused" ? (
                      ""
                    ) : (
                      <div>
                        <button
                          className="gold-outline-btn"
                          onClick={navigateToAddTrader}
                        >
                          <span className="me-2 fw-bold svgFont">
                            <CgAdd />
                          </span>
                          Add New Account
                        </button>
                      </div>
                    )}
                  </div>
                  <Table striped bordered hover variant="dark" responsive>
                    <thead>
                      <tr>
                        <th>Start Date</th>
                        <th>MT4 Account No</th>
                        <th>Broker Name</th>
                        <th>Initial Balance</th>
                        <th>Broker Server</th>
                        <th>Status</th>
                        <th>Profit Share Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {traders.length > 0 ? (
                        traders.map((trader, index) => {
                          return (
                            <tr key={index}>
                              <td>{trader.Date}</td>
                              <td>{trader.MT4_Acct_No}</td>
                              <td>{trader.BrokerName}</td>
                              <td>{trader.InitialBalance}</td>
                              <td>{trader.BrokerServer}</td>
                              <td>{trader.Status}</td>
                              <td className="theme-color">
                                {trader.ProfitShareStatus
                                  ? trader.ProfitShareStatus
                                  : "Disable"}
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <button
                                    className="me-2 iconBtn"
                                    onClick={() => editTrader(trader._id)}
                                  >
                                    <MdEditOff />
                                  </button>
                                  <button
                                    className="iconBtn"
                                    onClick={() => {
                                      deleteTrader(trader._id);
                                    }}
                                  >
                                    <MdDelete />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colspan={7} className="text-center">
                            {" "}
                            No Account Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Container>
            </div>
          </div>
      </>
    );
  } else {
    return <Navigate to="/login" />;
  }
};

Traders.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});
export default connect(mapStateToProps, { loginUser })(Traders);

// export default Traders;

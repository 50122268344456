import { GET_ERRORS } from "../actions/types";
const initialState = {
  isError: false,
  payload: null
};
export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ERRORS:
      return {
        ...state,
        isError: true,
        payload: action.payload
      };
    default:
      return {
        ...state,
        isError: false,
        payload: null
      };
  }
}
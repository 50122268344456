import React from 'react'
import './UserSlider.css'
import {Container, Row, Col} from 'react-bootstrap'
import Slider from "react-slick";
import qoute from '../../../assets/images/qoute.svg'
import slider1 from '../../../assets/images/slider1.png'
import multiMobile from '../../../assets/images/multiMobile.png'

const UserSlider = () => {
    const settings = {
        centerMode: true,
        centerPadding: '10px',
        slidesToShow: 1,
        speed: 500,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        responsive: [
        {
            breakpoint: 1399,
            settings: {
                arrows: true,
                slidesToShow: 1,
            },
            },
          {
            breakpoint: 768,
            settings: {
              arrows: true,
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              arrows: true,
              slidesToShow: 1,
            },
          },
        ],
      };
    return(
        <>
            <div className='section'>
                <Container fluid className='wideBox'>
                    <div className='userSlider'>
                    <Slider {...settings}>
                        <div>
                            <Row>
                                <Col sm={4} className='mb-sm-0 mb-3'>
                                    <img className='img-fluid' src={slider1} alt='Buy & Sell'></img>
                                </Col>
                                <Col sm={8} className='mb-sm-0 mb-3'>
                                    
                                    <div className='userText'>
                                        <img className='img-fluid mb-2' src={qoute} alt='Buy & Sell'></img>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut consectetur purus nulla sit pellentesque in pellentesque. Sit gravida aliquam velit in curabitur facilisis laoreet at. Vitae mi ac dis scelerisque eget nunc nunc. Nulla risus ac pretium hac fringilla pharetra vulputate eget. Molestie netus malesuada pulvinar enim. Neque, tellus velit facilisis urna id quis sit. Ut congue semper id consectetur amet libero feugiat. Fames condimentum ante tortor nunc, consequat feugiat id. Eleifend felis non vel dignissim nisi.</p>
                                        <p className='userTitle'>@username</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col sm={4} className='mb-sm-0 mb-3'>
                                    <img className='img-fluid' src={slider1} alt='Buy & Sell'></img>
                                </Col>
                                <Col sm={8} className='mb-sm-0 mb-3'>
                                    <div className='userText'>
                                        <img className='img-fluid mb-2' src={qoute} alt='Buy & Sell'></img>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut consectetur purus nulla sit pellentesque in pellentesque. Sit gravida aliquam velit in curabitur facilisis laoreet at. Vitae mi ac dis scelerisque eget nunc nunc. Nulla risus ac pretium hac fringilla pharetra vulputate eget. Molestie netus malesuada pulvinar enim. Neque, tellus velit facilisis urna id quis sit. Ut congue semper id consectetur amet libero feugiat. Fames condimentum ante tortor nunc, consequat feugiat id. Eleifend felis non vel dignissim nisi.</p>
                                        <p className='userTitle'>@username</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <Col sm={4} className='mb-sm-0 mb-3'>
                                    <img className='img-fluid' src={slider1} alt='Buy & Sell'></img>
                                </Col>
                                <Col sm={8} className='mb-sm-0 mb-3'>
                                    <div className='userText'>
                                        <img className='img-fluid mb-2' src={qoute} alt='Buy & Sell'></img>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut consectetur purus nulla sit pellentesque in pellentesque. Sit gravida aliquam velit in curabitur facilisis laoreet at. Vitae mi ac dis scelerisque eget nunc nunc. Nulla risus ac pretium hac fringilla pharetra vulputate eget. Molestie netus malesuada pulvinar enim. Neque, tellus velit facilisis urna id quis sit. Ut congue semper id consectetur amet libero feugiat. Fames condimentum ante tortor nunc, consequat feugiat id. Eleifend felis non vel dignissim nisi.</p>
                                        <p className='userTitle'>@username</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Slider>
                       
                        <div className='text-center'>
                            <img className='img-fluid' src={multiMobile} alt='Buy & Sell'></img>
                        </div>
                    </div>
                </Container>
                
            </div>
        </>
    )
}
export default UserSlider;
import React, { useState, useEffect } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import Header from "../DashboardHeader/Header";
import { AiOutlineDoubleRight } from "react-icons/ai";
import { Container, Table, Modal, Row, Col, Form, Button} from "react-bootstrap";
import "../Sidebar/Sidebar.css";
import { TickerTape } from "react-ts-tradingview-widgets";
import "reactjs-popup/dist/index.css";
import axios from "axios";
import Sidebar from "../Sidebar/Sidebar";
import { loginUser } from "../../actions/authActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import Select from "react-select";
import './Task.css'

const Traders = (props) => {
    const [tasks, setTasks] = useState([]);
    const [sidebar, setSidebar] = useState(true);
    
    const [status, setStatus] = useState(null)
    const [taskId, setTaskId] = useState(null)
    const [paymentProof, setPaymentProof] = useState(null)
    const [paymentModal, setPaymentModal] = useState(false)
    const [statusOptions, setStatusOptions] = useState([
		{ value: 0, label: "Un Paid" },
		{ value: 1, label: "Paid" },
		{ value: 2, label: "Partially Paid" },
	]);
    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          color: state.isSelected || state.isFocused ? 'white' : 'black',
          background: state.isSelected || state.isFocused ? '#d3ac43' : 'white',
        }),
        singleValue: (provided, state) => {
          const opacity = state.isDisabled ? 0.5 : 1;
          const transition = 'opacity 300ms';
      
          return { ...provided, opacity, transition };
        }
      }
    useEffect(() => {
        getTasks();
    }, []);
    const getTasks = () => {
        
        axios
            .get(
                `${process.env.REACT_APP_BASE_URL}/usertasks/get-user-task/` + props.auth.user.id
            )
            .then((res) => {
                // res => {}
                setTasks(res.data);
            })
            .catch((err) => { });
    };

    const updateStatus = (value, taskId_ = null) =>{
        setStatus(value);
            if((value > 0 && paymentProof) || value == 0 ){
                let formdata = new FormData()
                formdata.append("status",status ?? value);
                formdata.append("paymentProof",paymentProof);
                axios
                .put(
                    `${process.env.REACT_APP_BASE_URL}/usertasks/${taskId_ ?? taskId}`, 
                    formdata, 
                    {
                        headers: {
                          'Content-Type': 'multipart/form-data'
                        }
                    }
                )
                .then((res) => {
                    setTasks(res.data.data);
                    setPaymentModal(false)
                    setStatus(null);
                    setTaskId(null)
                    setPaymentProof(null)
                })
                .catch((err) => { });
            }else{
                setPaymentModal(true)
            }
    }
   

    if (props.auth.user.Verified) {
        return (
            <>
                
                    <div>
                        <div className="traders">
                            <Container fluid>
                                {/* <div className="wrapAnalytics">
                                    <TickerTape colorTheme="dark"></TickerTape>
                                </div> */}
                                <div className="mainTable">
                                    <div className="d-flex justify-content-sm-between align-items-center mb-md-4 mb-2">
                                        <h4>Tasks </h4>
                                    </div>
                                    <div className="table-responsive">
                                        <Table striped bordered hover variant="dark" responsive className="task-table">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Client Account</th>
                                                    <th>Dates</th>
                                                    <th>Amount Invested</th>
                                                    <th>Amount Profit</th>
                                                    <th>Admin Fee</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {tasks.length > 0 ? (
                                                    tasks.map((task, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    {index+1}
                                                                </td>
                                                                <td>
                                                                    MT4_Acct_No: {task.trader.MT4_Acct_No}<br/>
                                                                    BrokerName: {task.trader.BrokerName}
                                                                </td>
                                                                <td>
                                                                    <p>
                                                                        {moment(task.startDate).format('DD-MMM-YYYY')} 
                                                                    </p>
                                                                    <p>Till</p>
                                                                    <p>
                                                                        {moment(task.endDate).format('DD-MMM-YYYY')}
                                                                    </p>
                                                                </td>
                                                                    
                                                                <td>{task.amountInvested}</td>
                                                                <td>{task.amountProfit}</td>
                                                                <td>{task.adminFee} ( {task.percentageProfit ?? '30'}% )</td>
                                                                <td className="td-actions">
                                                                    {task.status == 3 && 
                                                                        <>
                                                                            Payment Outstanding<br/>
                                                                            Kindly upload a payment proof
                                                                        </>
                                                                    } 
                                                                    {task.status != 1 ? 
                                                                        <Select
                                                                            className="basic-single"
                                                                            options={statusOptions}
                                                                            onChange={(option) => {
                                                                                setTaskId(task._id)
                                                                                updateStatus(option.value, task._id)
                                                                            }}
                                                                            value={statusOptions?.filter(
                                                                                (option) => option.value === task.status
                                                                            )}
                                                                            styles={customStyles}
                                                                        />
                                                                        :
                                                                        "Paid"
                                                                    }
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                ) : (
                                                    <tr>
                                                        <td colspan={7} className="text-center">
                                                            {" "}
                                                            No Task Found
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Container>
                        </div>
                    </div>
                    <Modal
                        className="modal-primary"
                        onHide={() => setPaymentModal(!paymentModal)}
                        show={paymentModal}
                    >
                        <Modal.Header className="justify-content-center">
                            <Row>
                                <div className="col-12">
                                    <h4 className="mb-0 mb-md-3 mt-0">Upload Payment Proof</h4>
                                </div>
                            </Row>
                        </Modal.Header>
                        <Modal.Body>
                            <Form className="text-left">
                                <div className="d-flex name-email">
                                    <Form.Group className="flex-fill d-flex align-items-center">
                                        <Form.Control type="file" onChange={(e) => setPaymentProof(e.target.files[0])} />
                                    </Form.Group>
                                </div>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                className="btn btn-info"
                                onClick={() => setPaymentModal(!paymentModal)}
                            >
                                Close
                            </Button>
                            <Button
                                className="btn btn-danger"
                                onClick={() =>  updateStatus(status)}
                            >
                                Update Status
                            </Button>
                        </Modal.Footer>
                    </Modal>
            </>
        );
    } else {
        return <Navigate to="/login" />;
    }
};

Traders.propTypes = {
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});
export default connect(mapStateToProps, { loginUser })(Traders);

// export default Traders;

import React from 'react'
import {BrowserRouter, Routes, Route} from 'react-router-dom';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './assets/css/style.css';
import './App.css'
import Home from './components/Home/Home'
import Dasboard from './components/Dashboard/Dashboard'
import DasboardHome from './components/Dashboard/DashboardHome/DashboardHome'
import Signup from './components/Home/Signup/Signup'
import Login from './components/Home/Login/Login'
import Forgot from './components/Home/Forgot/Forgot'
import AddTrader from './components/Dashboard/Traders/AddTrader/AddTrader'
import EditTrader from './components/Dashboard/Traders/EditTrader'
import EditProfile from './components/Dashboard/EditProfile/EditProfile';
import Traders from './components/Dashboard/Traders/Traders';
import Reporting from './components/Dashboard/Reporting/Reporting';
import Validator from './components/Validator';
import ForgetConfirm from './components/ForgetConfirm';
import CheckEmail from './components/CheckEmail';
import Tasks from './components/Dashboard/Tasks/Tasks';
import DashboardLayout from './layouts/DashboardLayout';
import store, {persistor} from "./store";
import { PrivateRoute } from './privateRoute';
import setAuthToken from "./components/utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./components/actions/authActions";
import { Provider } from "react-redux";
import jwt_decode from "jwt-decode";
import Thankyou from './components/Thankyou';

import PasswordChanged from './components/PasswordChanged';
if (localStorage.jwtToken) {
  const token = localStorage.jwtToken;
  setAuthToken(token);
  const decoded = jwt_decode(token);
  store.dispatch(setCurrentUser(decoded));
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());
    window.location.href = "./login";
  }
}

function App() {
  
  return (
    <>
      <div>
        {/* <DashboardLayout>
          <React.Fragment>
            <Routes>
                <Route index element = {<Home />}></Route>              
                <Route path="/signup" element={<Signup />} />
                <Route path="/login" element={<Login />} />
                <Route path="/forgot" element={<Forgot />} />
                <Route path="/checkemail" element={<CheckEmail />} />
                <Route path="/passwordchanged" element={<PasswordChanged />} />
                <Route path="/verifyEmail/:id" element={<Validator/>} />
                <Route path="/auth_user/resetpassword/:id/:token" element={<ForgetConfirm/>} />
                <Route path="/thankyou" element={<Thankyou />} />
                <Route path="/addTrader" element={<AddTrader/>} />


                <Route path="/dashboard" exact element={<DasboardHome/>}/>
                <Route path="/acounts" element={<Traders/>} />
                <Route path="/acounts/:status" element={<Traders/>} />
                <Route path="/tasks" element={<Tasks/>} />

                <Route path="/editTrader/:id" element={<EditTrader/>} />
                <Route path="/editProfile" element={<EditProfile/>} />
              
                
                <Route path="/reporting" element={<Reporting/>} />
            </Routes>
          
          </React.Fragment>
        </DashboardLayout> */}
      <React.Fragment>
        <Routes>
            <Route index element = {<Home />}></Route>              
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot" element={<Forgot />} />
            <Route path="/checkemail" element={<CheckEmail />} />
            <Route path="/passwordchanged" element={<PasswordChanged />} />
            <Route path="/verifyEmail/:id" element={<Validator/>} />
            <Route path="/auth_user/resetpassword/:id/:token" element={<ForgetConfirm/>} />
            <Route path="/thankyou" element={<Thankyou />} />
            <Route path="/addTrader" element={<AddTrader/>} />

            <Route path="/dashboard" exact element={
                <DashboardLayout>
                  <DasboardHome/>
                </DashboardLayout>
            }
            />
              <Route path="/acounts" element={
                <DashboardLayout>
                  <Traders/>
                </DashboardLayout>
            } />
            <Route path="/acounts/:status" element={
                <DashboardLayout>
                  <Traders/>
                </DashboardLayout>
            } />
            <Route path="/tasks" element={
                <DashboardLayout>
                  <Tasks/>
                </DashboardLayout>
            } />

            <Route path="/editTrader/:id" element={<EditTrader/>} />
            <Route path="/editProfile" element={<EditProfile/>} />
          
            
            <Route path="/reporting" element={
                <DashboardLayout>
                  <Reporting/>
                </DashboardLayout>
            } />
        </Routes>
      </React.Fragment>
      </div>
    </>
  );
}

export default App;
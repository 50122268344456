import React from 'react'
import './Home.css'
// import Header from '../Header/Header'
// import Footer from '../Footer/Footer'
// import Login from './Login/Login'
// import PopularDishSlider from './PopularDishSlider/PopularDishSlider'
// import Reservation from './Reservation/Reservation'
// import Delivery from './Delivery/Delivery'
// import Reviews from './Reviews/Reviews'
// import DownloadApp from './DownloadApp/DownloadApp'
// import MenuSlider from './MenuSlider/MenuSlider'
import Banner from './Banner/Banner'
import SiteCard from './SiteCard/SiteCard'
import BuySell from './BuySell/BuySell'
import SuccessfulTrader from './SuccessfulTrader/SuccessfulTrader'
import Cost from './Cost/Cost'
import UserSlider from './UserSlider/UserSlider'
import JoinNow from './JoinNow/JoinNow'
import Sidebar from '../Dashboard/Sidebar/Sidebar'
    
const Home = () => {
    return(
        <>
        {/* <Sidebar/> */}
            <div className='home'>
                <Banner/>
                <SiteCard/>
                <BuySell/>
                <SuccessfulTrader/>
                <Cost/>
                <UserSlider/>
                <JoinNow/>
            </div>
            {/* <Login/> */}
            {/* <Banner/>
            <PopularDishSlider/>
            <Delivery/>
            <MenuSlider/>
            <Reservation/>
            <Reviews />
            <DownloadApp />
            <Footer/> */}
        </>
    )
}
export default Home;
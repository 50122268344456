import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Header.css";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import logo from "../../../assets/images/headerLogo.png";
import profile from "../../../assets/images/no-image.png";
import { FiBell } from "react-icons/fi";
import { TickerTape } from "react-ts-tradingview-widgets";

///////////////////// for logout
import { logoutUser,beforeAuth } from "../../actions/authActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//////////////////////////////
import axios from 'axios';
import { useEffect } from "react";



const Header = (props) => {

    // /////////////////////////changes for edit -- saud
    const [userData, setUserData] = useState([]);

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_BASE_URL}/auth_user/getsingleuser/${props.auth.user.id}`)
            .then((response) => {
                console.log('response',response)
                const Data = response.data[0];
                setUserData(Data);
            })
    }, [])
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_BASE_URL}/auth_user/getsingleuser/${props.auth.user.id}`)
            .then((response) => {
                const Data = response.data[0];
                setUserData(Data);
                props.beforeAuth()
            })
    }, [props.auth.image_updated])
    const onLogout = () => {
        props.logoutUser();
    };
    return (
        <>
            <div className="dashboard-header">
                <div className="header-top">
                    <Navbar expand="lg" className="py-0">
                        <Container fluid>
                            <Navbar.Brand href="/">
                                <img className="img-fluid" src={logo} alt="Midas Gold"></img>
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="ms-auto">
                                    <div className="wrapMenu">
                                        
                                        <NavDropdown
                                            title={
                                                <div className="profile-item">
                                                    <div>
                                                        <img className="img-fluid" src={userData.Photo ? process.env.REACT_APP_MEDIA_URL + 'profilePic/' + userData.Photo : profile} alt="Profile" />
                                                    </div>
                                                    <div className="profile-text">
                                                        <p className="profile-title">{userData.FirstName} {userData.LastName} </p>
                                                    </div>
                                                </div>
                                            } id="navbarScrollingDropdown">

                                            <NavDropdown.Item>
                                                <Link className="menu-link" to={'/editProfile'}> Edit Profile </Link>
                                            </NavDropdown.Item>
                                            
                                        </NavDropdown>
                                    </div>
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                </div>
                <div className="wrapAnalytics">
                    <TickerTape colorTheme="dark"></TickerTape>
                </div>
            </div>

        </>
    );
};

Header.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
    auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser, beforeAuth})(Header);

// export default Header;

import React from 'react'
import './BuySell.css'
import {Container, Row, Col} from 'react-bootstrap'
import mobile from '../../../assets/images/mobile.png'

const BuySell = () => {
    return(
        <>
            <div className='section'>
                <Container fluid className='wideBox'>
                    <div className='buySell'>
                        <Row className='flex-md-row flex-column-reverse'>
                            <Col md={6} className='mb-md-0 mb-3'>
                                <div className='buyImg'><img className='img-fluid' src={mobile} alt='Buy & Sell'></img></div>
                            </Col>
                            <Col md={6} className='mb-md-0 mb-3'>
                                <div className='buyText'>
                                    <p className='fw-bold theme-color'>Gold is the most competitive industry, with daily fluctuations ranging from 300 to 1000 pips.</p>
                                    <p className='fw-bold'>Based on our extensive gold market expertise and gold trading experience throughout the years we will provide you with the three criteria listed below to analyse the best gold signal:</p>
                                    <ul className='buyList'>
                                        <li><p>It must adhere to the correct gold trend. </p></li>
                                        <li><p>It has a clear price entry and target as well as a low risk / reward ratio. </p></li>
                                        <li><p>It has well-defined price and a secure stop level</p></li>
                                    </ul>
                                    <p>Many inexperienced traders believe that a gold signal service with an accuracy rate of 85%-90% guarantees a profitable deal. Please be advised that this statement is incorrect. P recision isn't enough:</p>
                                    <p>To achieve good trading results you should concentrate on obtaining a gold signal with a favorable risk:reward ratio. A 1:3 risk reward gold signal will produce a positive outcome even if you have 60% accuracy, however an 80%-90% accuracy with high-risk/low reward will produce a negative result.</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    )
}
export default BuySell;
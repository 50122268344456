import React, { useState, useEffect } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import Header from "../DashboardHeader/Header";
import { AiOutlineDoubleRight } from "react-icons/ai";
import { Container, Table } from "react-bootstrap";
import "../Sidebar/Sidebar.css";
import { TickerTape } from "react-ts-tradingview-widgets";
import "reactjs-popup/dist/index.css";
import axios from "axios";
import Sidebar from "../Sidebar/Sidebar";
import { loginUser } from "../../actions/authActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";

import Select from 'react-select';
import FullPageLoader from '../../FullPageLoader/FullPageLoader';
import DateRangePicker from 'react-bootstrap-daterangepicker';
// import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

const Traders = (props) => {
    const [tasks, setTasks] = useState([]);
    const [sidebar, setSidebar] = useState(true);
    const [traderOptions, setTraderOptions] = useState([]);
    const [trader, setTrader] = useState([]);
    const [totalInvested, setTotalInvested] = useState(0);
    const [totalProfit, setTotalProfit] = useState(0);
    const [startDate, setStartDate] = useState(0);
    const [endDate, setEndDate] = useState(0);
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        getTasks();
        getTraders();
    }, []);
    useEffect(() => {
        getTasks();
    }, [trader, startDate, endDate]);
    useEffect(() => {
        let investment = 0
        let profit = 0
        tasks.map(task => {
            investment += task.amountInvested
            profit += task.amountProfit
        });
        setTotalInvested(investment)
        setTotalProfit(profit)
    }, [tasks]);

    const getTraders = () => {
        axios
            .get(
                `${process.env.REACT_APP_BASE_URL}/usertraderacct/customertraderacct/` + props.auth.user.id+'/all'
            )
            .then((res) => {
                let trad = [{ value:'', label: "Select Client Account"}]
                res.data.map((data_ => {
                    trad.push( { value: data_._id, label: data_.BrokerName})
                }));
                setTraderOptions(trad)
            })
            .catch((err) => { });
    };
    const getTasks = () => {
        let qs = ''
        if(trader)
        {
            qs = "traderId="+trader
        }
        if( startDate && endDate ){
            
            qs += "&startDate="+moment(startDate).format('MM-DD-YYYY')
            qs += "&endDate="+moment(endDate).format('MM-DD-YYYY')
        } 
        setShowLoader(true)
        axios
            .get(
                `${process.env.REACT_APP_BASE_URL}/usertasks/get-user-task/` + props.auth.user.id+"?"+qs
            )
            .then((res) => {
                setShowLoader(false)

                // res => {}
                setTasks(res.data);
            })
            .catch((err) => { });
    };
    const  handleTraderChange = (value) => {
        setTrader(value)    
    }
    const  handleDateChange = (start, end) => {
        setStartDate(start)   
        setEndDate(end)   
    }
    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          color: state.isSelected || state.isFocused ? 'white' : 'black',
          background: state.isSelected || state.isFocused ? '#d3ac43' : 'white',
        }),
       
        singleValue: (provided, state) => {
          const opacity = state.isDisabled ? 0.5 : 1;
          const transition = 'opacity 300ms';
      
          return { ...provided, opacity, transition };
        }
      }

    if (props.auth.user.Verified) {
        return (
            <>
                <div>
                    <div className="traders">
                        <Container fluid>
                            {/* <div className="wrapAnalytics">
                                <TickerTape colorTheme="dark"></TickerTape>
                            </div> */}
                            <div className="mainTable">
                                <div className="d-flex justify-content-sm-between align-items-center mb-md-4 mb-2">
                                    <h4 className="col-md-4">Reporting </h4>
                                    <div className="col-md-4 right">
                                        <h4>Total investment: {totalInvested}</h4>
                                        <h4>Total Profit: {totalProfit}</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <label>Select Client Account</label>
                                        <Select
                                            styles={customStyles}
                                            className="basic-single"
                                            name="UserID"
                                            options={traderOptions}
                                            onChange={(option) => handleTraderChange(option.value)}
                                            value={traderOptions?.filter(option => option.value === trader)}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <DateRangePicker
                                            onCallback={(start, end, label) => {
                                                handleDateChange(start, end)
                                                // setStartDate(moment(start).format('YYYY-MM-DD'))
                                                // setEndDate(moment(end).format('YYYY-MM-DD'))
                                            }}
                                        >    
                                        {startDate ? 
                                            <label class="btn gold-outline-btn">{moment(startDate).format('DD/MM/YYYY') +' - '+ moment(endDate).format('DD/MM/YYYY')} </label>
                                            :
                                            <label class="btn gold-outline-btn">Select Date Range </label>
                                        }
                                        </DateRangePicker>
                                    </div>
                                </div>

                                <Table striped bordered hover variant="dark" responsive>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Client Account</th>
                                            <th>Dates</th>
                                            <th>Amount Invested</th>
                                            <th>Amount Profit</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {tasks.length > 0 ? (
                                            tasks.map((task, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            {index+1}
                                                        </td>
                                                        <td>{task.trader.MT4_Acct_No}<br></br>{task.trader.BrokerName}</td>
                                                        <td>{moment(task.startDate).format('DD/MMMM/YYYY')} - {moment(task.endDate).format('DD/MMMM/YYYY')}</td>
                                                        <td>{task.amountInvested}</td>
                                                        <td>{task.amountProfit}</td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colspan={7} className="text-center">
                                                    {" "}
                                                    No Task Found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </Container>
                    </div>
                </div>
            </>
        );
    } else {
        return <Navigate to="/login" />;
    }
};

Traders.propTypes = {
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});
export default connect(mapStateToProps, { loginUser })(Traders);

// export default Traders;

import React from 'react'
import './Cost.css'
import {Container, Row, Col} from 'react-bootstrap'
import cost from '../../../assets/images/cost.png'
import dashboard from '../../../assets/images/dashboard.png'
import vantage from '../../../assets/images/vantage.svg'

const Cost = () => {
    return(
        <>
            <div className='section'>
                <Container fluid className='wideBox'>
                    <div className='cost'>
                        <Row className='costRows flex-md-row flex-column-reverse'>
                            <Col md={6} className='mb-md-0 mb-3'>
                                <div className='costGraph'><img className='img-fluid' src={cost} alt='Buy & Sell'></img></div>
                            </Col>
                            <Col md={6} className='mb-md-0 mb-3'>
                                <div>
                                    <h2 className='theme-color'>Inexperience can cost.</h2>
                                    <p>Online trading is quite popular, and it's easy to see why. If you have a low-paying job or are preparing for the purchase of a new house or car, it is tempting to perceive internet trading as an easy method to make extra money</p>
                                    <p>However, according to the statistics, the majority of new traders lose their capital within the first six months. 76% of retail CFD accounts lose money.</p>
                                    <p>Even skilled traders can make poor decisions and suffer losses. You could be putting your money at risk if you don't understand how the markets function.</p>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} className='mb-md-0 mb-3'>
                                <div>
                                    
                                    <p>Many online trading platforms provide free signals. Though this may appear to be an appealing concept it can be just as risky as trading on your own.</p>
                                    <p>Free services may not always provide daily updates and can overwhelm their users with information that is difficult to understand, leaving you to make uneducated judgements</p>
                                    <p className='theme-color'>It's simple, straightforward and most importantly secure. We help you keep your eye on the ball.</p>
                                </div>
                            </Col>
                            <Col md={6} className='mb-md-0 mb-3'>
                                <div className='costImg'>
                                    <img className='img-fluid img-fluid float-md-end' src={dashboard} alt='Buy & Sell'></img>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <div className='vantage text-center'>
                    <img className='img-fluid' src={vantage} alt='Buy & Sell'></img>
                </div>
            </div>
        </>
    )
}
export default Cost;